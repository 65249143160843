import { NETWORKS } from "./constants/Constant";

const addNetwork = (network) => {
    let { chainId, chainName, nativeCurrency, rpcUrls, SCAN_BASE_URL } = network;
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{ chainName, nativeCurrency, rpcUrls, blockExplorerUrls: [SCAN_BASE_URL], chainId: toHexaDecimal(chainId) }],
    });
  }
  const toHexaDecimal = (number) => `0x${Number(number).toString(16)}`;

  const switchNetwork = (chainID) =>
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHexaDecimal(chainID)}],
    });
  
  export const switchAddNetwork = async (chainID) => {
    try {
      await switchNetwork(chainID);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await addNetwork(NETWORKS[chainID]);
        } catch (error) {
         }
      }
    }
  };
  