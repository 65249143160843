export const CHAIN_IDS = {
  MAINNET: 1,
  TESTNET: 4,
};
export const NETWORK_NAMES = {
  MAINNET: "Ethereum",
  TESTNET: "Rinkeby ",
};
export const metaMaskExtensionInstallationLink =
  "https://metamask.app.link/dapp/window.location/";
export const NETWORKS = {
  1: {
    chainId: 1,
    chainName: "Ethreum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://mainnet.infura.io/v3/${INFURA_API_KEY}"],

    SCAN_BASE_URL: "https://etherscan.io",
  },
  4: {
    chainId: 4,
    chainName: "Rinkeby Test Network",
    nativeCurrency: { name: "Rinkeby", symbol: "RinkebyETH", decimals: 18 },
    rpcUrls: ["https://rinkeby.infura.io/v3/"],

    SCAN_BASE_URL: "https://rinkeby.etherscan.io",
  },
};
