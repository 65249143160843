import "./landingPage.css";
import Modal from "react-modal";
import Close from "../assets/images/close.png";
// import Img from "../assets/images/img.png";
import MblImg from "../assets/images/mbl-img-without-opensea-button.png";
import WebImg from "../assets/images/web-img-without-opensea-button.png";
// import Gift from "../assets/images/gift.png";
import Plus from "../assets/images/plus.png";
import Minus from "../assets/images/minus.png";
import { useEffect, useState } from "react";
import ConnectButton from "./ConnectButton";
import Mint from "../artifacts/abi/Mint.json";
import { ethers } from "ethers";
import { switchAddNetwork } from "../utils";
import { CHAIN_IDS, NETWORKS, NETWORK_NAMES } from "../constants/Constant";
import loaderImage from "../assets/images/loader.gif";

export const metaMaskExtensionInstallationLink =
  "https://metamask.app.link/dapp/window.location/";

export default function LandingPage() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [correctChain, setCorrectChain] = useState(false);
  const [transaction, setTranasction] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [mintedNFTs, setMintedNFTs] = useState(0);
  const [error, setError] = useState("");
  const [mintingLimit, setMintingLimit] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);


  console.log("checking CICD")
  const InstallMetamaskRedirect = () => {
    setTimeout(
      () => window.open(metaMaskExtensionInstallationLink, "_blank"),
      1000
    );
  };

  const fetchContract = () => {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(prov);
      const signer = prov.getSigner();
      try {
        const con = new ethers.Contract(
          process.env.REACT_APP_MINT_CONTRACT_ADDRESS,
          Mint,
          signer
        );
        setContract(con);

      } catch (err) {
        console.log("checkonng contract error",err.message)
      }
      
      window.ethereum.on("chainChanged", () => fetchContract());
    } else {
      InstallMetamaskRedirect();
    }
  };

  useEffect(() => {
    fetchContract();
  }, []);

  const fetchMintingdata = async () => {
    if (provider) {
      const totalmintednft = await contract.totalSupply();
      const totalnft = await contract.maxSupply();
      setMintedNFTs(totalmintednft.toNumber());
      setTotalNFTs(totalnft.toNumber());
      console.log("mintedNFTs", mintedNFTs);
      console.log("totalNFTs", totalNFTs);
      console.log(totalmintednft.toNumber(), "totalmintednft");
      console.log(totalmintednft.toNumber(), "totalmintednft");
      if (totalmintednft.toNumber() === totalnft.toNumber()) {
        setError("Mint Completed");
      }
    }
  };

  useEffect(() => {
    console.log("mimting errpr",error)
    fetchMintingdata();
    getAddressLimit();
    console.log("checkignanna");
    //  setMintedNFTs(contract.totalSupply())
    //  setTotalNFTs(contract.maxSupply())
  }, [address, provider]);

  const getAddressLimit = async () => {
    if (provider) {
      let limitRemaining = 0;
      const reserved = await contract.reservedNFTAddresses(address);
      if (reserved.mintLimit.toNumber() === 0) {
        const publicLimit = await contract.publicMintLimitPerWallet();
        const userMintedCount = await contract.publicMintCounterPerWallet(
          address
        );
        limitRemaining =
          publicLimit.toNumber() -
          (userMintedCount.toNumber() ? userMintedCount.toNumber() : 0);
      } else {
        limitRemaining =
          reserved.mintLimit.toNumber() - reserved.mintedCount.toNumber()>3?3:reserved.mintLimit.toNumber() - reserved.mintedCount.toNumber();
      }
      if (limitRemaining === 0 || quantity > limitRemaining) {
        setError(`Minting Limit reached`);
      }
      setMintingLimit(limitRemaining);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setTranasction("");
    setQuantity(1);
  }
  async function mintNFTs() {
    console.log("called");
    if (!mintingLimit || mintingLimit < quantity) {
      return;
    }
    setTranasction("");
    setError("");
    // Check quantity
    if (quantity < 1) {
      setError("You need to mint at least 1 NFT.");
      // setMintError(true)
      // mintQuantityInputRef.current.focus()
      return;
    }
    if (quantity > 3) {
      setError("You cannot only mint a maximum of 3 NFTs.");
      // setMintError(true)
      // mintQuantityInputRef.current.focus()
      return;
    }

    // Get wallet details
    if (!(window !== "undefined" && typeof window.ethereum !== "undefined"))
      return;
    try {
      // setMintLoading(true);
      // Interact with contract
      setIsLoading(true);
      const transaction = await contract.mintNFT(quantity);
      setTranasction(transaction);
      setIsLoading(false);
      await transaction.wait();
      fetchMintingdata();
      getAddressLimit();
      console.log(`Congrats, you minted ${quantity} token(s)!`);
      // setMintError(false)
    } catch (error) {
      setIsLoading(false);
      setError("User denied transaction signature");
      console.log(error.message, "error.message");
      console.log("Connect your wallet first.");
      // setMintError(true)
    }
    // setMintLoading(false)
  }
console.log("contract",contract)
console.log("process.env.REACT_APP_MINT_CONTRACT_ADDRESS",process.env.REACT_APP_MINT_CONTRACT_ADDRESS)
console.log("process.env.REACT_APP_NETWORK_TYPE",process.env.REACT_APP_NETWORK_TYPE)
console.log(process.env.REACT_APP_MINTING,"process.env.REACT_APP_MINTING")


  return (
    <>
      <section className="hero-section">
        <img className="web-image" src={WebImg} alt="img" />
        <img className="mbl-image" src={MblImg} alt="img" />
        {/* <a
          href="https://opensea.io/explore-collections"
          target="blank"
          className="click-btn"
        >
          click me
        </a> */}
        <a
          href="https://twitter.com/9Livesnft"
          target="blank"
          className="twitter-btn"
        >
          click
        </a>
        {
          <ConnectButton
            setAddress={setAddress}
            setCorrectChain={setCorrectChain}
          />
        }
        {
          <button className="open-modal" onClick={openModal}>
            mint
          </button>
        }
      </section>
      {
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <img
            className="close-modal"
            onClick={closeModal}
            src={Close}
            alt="close"
          />
          {
            <div className="modal-content">
              <div className="mint-content">
                <h2>Mint 9lives</h2>
                <div className="total-counter">
                  <span>{mintedNFTs}</span>
                  <span>/</span>
                  <span>{totalNFTs}</span>
                </div>
                <div className="modal-input">
                  <img
                    src={Minus}
                    alt="minus"
                    onClick={() => {
                      if (quantity >= 2 && address) setQuantity(quantity - 1);
                    }}
                  />
                  <span>{quantity}</span>
                  <img
                    src={Plus}
                    alt="plus"
                    onClick={() => {
                      address &&
                        mintingLimit &&
                        quantity < mintingLimit &&
                        mintedNFTs < totalNFTs &&
                        setQuantity(quantity + 1);
                    }}
                  />
                </div>
                {!address && (
                  <ConnectButton
                    isOpen={modalIsOpen}
                    setAddress={setAddress}
                    setCorrectChain={setCorrectChain}
                  />
                )}
                {/* {process.env.REACT_APP_MINTING === "false" && (
                  <p className="mint-text">
                    Minting will be Start from 30th of July, 2022 at 11 PM Dubai
                    Time & 7 PM UTC.
                  </p>
                )} */}
                {address && (
                  <button
                    className="connect-button cursor"
                    onClick={() => {
                      correctChain
                        ? !isLoading && mintedNFTs < totalNFTs && mintNFTs()
                        : switchAddNetwork(
                            CHAIN_IDS[process.env.REACT_APP_NETWORK_TYPE]
                          );
                    }}
                  >
                    {correctChain ? (
                      isLoading && mintedNFTs === totalNFTs ? (
                        <img
                          className="loader"
                          src={loaderImage}
                          alt="loading..."
                        />
                      ) : (
                        "Mint"
                      )
                    ) : (
                      "Change to Correct Network"
                    )}
                  </button>
                )}
                {isLoading ? (
                  <span className="loader-text">
                    Minting <small></small>
                    <small></small>
                    <small></small>
                  </span>
                ) : (
                  ""
                )}
                {
                  !correctChain &&
                  address && (
                    <p className="error-text">
                      Incorrect chain - please switch to the{" "}
                      {NETWORK_NAMES[process.env.REACT_APP_NETWORK_TYPE]}{" "}
                      network
                    </p>
                  )}
                { error && (
                  <p className="error-text">{error}</p>
                )}
              </div>
              {transaction ? (
                <div className="congratulations">
                  <p className="transaction-msg">
                    View your Transaction on{" "}
                    <a
                      href={`${
                        NETWORKS[CHAIN_IDS[process.env.REACT_APP_NETWORK_TYPE]]
                          .SCAN_BASE_URL
                      }/tx/${transaction.hash}`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Block Explorer
                    </a>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          }
        </Modal>
      }
    </>
  );
}
